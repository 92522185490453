<template>
	<div :style="{ 'min-height': '200px' }">
		<assigned-projects :has-project="hasProject" :is-asigned-project-sidebar-active.sync="isAsignedProjectSidebarActive" :user_id="user_id" @isAssign="getList" @refetch-data="refetchUserData" />

		<!-- 分配项目 -->
		<div class="text-right">
			<b-button size="sm" variant="primary" @click="clickbtn">
				<feather-icon icon="FolderPlusIcon" />
				<span class="text-nowrap ml-1">{{ $t("AssignedProjects") }}</span>
			</b-button>
		</div>

		<!-- Form: Personal Info Form -->
		<b-table
			:empty-text="$t('No Data')"
			:fields="$t('tableColumnsP')"
			:items="$store.state.users.userData.projects"
			:sort-by.sync="sortBy"
			:sort-desc.sync="isSortDirDesc"
			class="position-relative table mt-2"
			primary-key="id"
			responsive
			show-empty
			style="table-layout: fixed; word-break: break-all"
		>
			<template v-slot:table-colgroup="scope">
				<col
					v-for="field in scope.fields"
					:key="field.key"
					:style="{
						width: field.key === 'description' ? '40%' : '' || field.key === 'index' ? '5%' : '',
					}"
				/>
			</template>
			<template #cell(index)="data">
				<div style="width: 2rem">
					{{ data.index + 1 }}
				</div>
			</template>
			<template #cell(project_name)="data">
				<div>
					{{ data.item.project_name }}
				</div>
			</template>

			<!-- Column: Role -->
			<!-- 修改角色 -->
			<template #cell(role)="data">
				<div class="text-nowrap" style="width: 10rem">
					<v-select v-model="data.item.role.id" :clearable="false" :options="data.item.roles" :reduce="(item) => item.id" label="name" @input="updateRole(data.item.role.id, data)" />
				</div>
			</template>
			<template #cell(description)="data" class="w-30">
				<b-row>
					<b-col>
						<div class="text-wrap">
							{{ data.item.description }}
						</div>
					</b-col>
				</b-row>
			</template>
			<template #cell(location)="data">
				<div class="text-nowrap">
					<b-row>
						<b-col cols="12" md="12">
							经度：
							<span>
								{{ data.item.lng }}
							</span>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="12" md="12">
							纬度：
							<span>
								{{ data.item.lat }}
							</span>
						</b-col>
					</b-row>
				</div>
			</template>
			<template #cell(action)="data">
				<div class="align-middle text-center">
					<b-button class="btn-icon" size="sm" variant="danger" @click="delectPoject(data.item.attach_id)">
						<feather-icon icon="Trash2Icon" />
					</b-button>
				</div>
			</template>
		</b-table>
	</div>
</template>

<script>
	import flatPickr from "vue-flatpickr-component";
	import { computed, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import store from "@/store";
	import AssignedProjects from "./AssignedProject.vue";
	import { showConfirm } from "@/libs/utils/showConfirm";
	import { showToast } from "@/libs/utils/showToast";
	import vSelect from "vue-select";

	export default {
		components: {
			vSelect,
			flatPickr,
			AssignedProjects,
		},
		props: {
			userData: [Object],
		},
		setup(props) {
			const event = reactive({
				selected: null,
				data: {},
				hasProject: [],
				list: "",
				location: "",
				sortBy: "id",
				isSortDirDesc: true,
				isAsignedProjectSidebarActive: false,
				user_id: computed(() => {
					return props.userData.user_id;
				}),
			});

			watch(
				() => props.userData.projects,
				(val) => {
					if (val) {
						event.hasProject = [];
						for (let item of val) {
							event.hasProject.push(item.project_id);
						}
					}
				}
			);

			const getList = (data) => {
				event.list = data;
			};

			const delectPoject = (id) => {
				showConfirm("确认删除?", () => {
					store
							.dispatch("users/detachProject", {attach_id: id})
						.then((response) => {
							if (response) {
								showToast(null, "删除完成", "您已成功删除该项目");
							}
						})
						.catch((error) => {
							showToast(error);
						});
				});
			};

			const updateRole = (value, data) => {
				showConfirm("确认更改?", () => {
					store
						.dispatch("users/changeRole", {
							attach_id: data.item.attach_id,
							role_id: value,
						})
						.then((response) => {
							if (response) {
								showToast(null, "更改完成", "您已成功更改该角色");
							}
						})
						.catch((error) => {
							showToast(error);
						});
				});
			};

			const clickbtn = () => {
				event.isAsignedProjectSidebarActive = false;
				if (event.list instanceof Array) {
					if (event.list.length === 0) {
						showToast(null, "提示", "已添加所有项目");
					} else {
						event.isAsignedProjectSidebarActive = true;
					}
				} else {
					showToast(null, "提示", "发生异常，请重试");
				}
			};

			const refetchUserData = function () {
				store
					.dispatch("users/fetchUser", event.user_id)
					.then((response) => {
						event.data = response.data;
						store.commit("users/UPDATE_USER_USERDATA", response.data);
					})
					.catch((error) => {
						showToast(error);
					});
			};

			onMounted(() => {
				event.data = JSON.parse(JSON.stringify(props.userData));
				event.hasProject = [];
				if (event.data.projects) {
					for (let item of props.userData.projects) {
						event.hasProject.push(item.project_id);
					}
				}
			});

			return {
				...toRefs(event),
				refetchUserData,
				clickbtn,
				getList,
				delectPoject,
				updateRole,
			};
		},
	};
</script>

<style lang="scss">
	@import "src/@core/scss/vue/libs/vue-flatpicker.scss";

	.table th:nth-last-child(1) {
		text-align: center;
	}
</style>
