<template>
	<b-sidebar
		id="asigned-project"
		:visible="isAsignedProjectSidebarActive"
		backdrop
		bg-variant="white"
		no-header
		right
		shadow
		sidebar-class="sidebar-lg"
		@change="(val) => $emit('update:is-asigned-project-sidebar-active', val)"
		@hidden="resetForm"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					<feather-icon class="mr-1" icon="FolderPlusIcon" size="16" />
					分配项目
				</h5>

				<feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
			</div>

			<!-- BODY -->
			<validation-observer ref="refFormObserver" #default="{ handleSubmit }">
				<!-- Form -->
				<b-form class="p-2">
					<!-- Project -->
					<validation-provider #default="{ errors }" name="Project_name" rules="required">
						<b-form-group label="项目名称" label-for="Project_name">
							<v-select v-model="blankUserData.project_id" :options="projectList" :reduce="(project) => project.project_id" :state="errors.length > 0 ? false : null" label="project_name" @input="selectProject(blankUserData.project_id)" />
							<small ref="errorText1" class="text-danger">{{ errors[0] }}</small>
						</b-form-group>
					</validation-provider>
					<!-- User Role -->
					<validation-provider #default="{ errors }" name="User Role" rules="required">
						<b-form-group label="用户角色" label-for="user-role">
							<v-select v-model="blankUserData.role_id" :options="roleOptions" :reduce="(role) => role.id" :state="errors.length > 0 ? false : null" label="name" />
							<small ref="errorText2" class="text-danger">{{ errors[0] }}</small>
						</b-form-group>
					</validation-provider>

					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button class="mr-1" size="sm" type="submit" variant="primary" @click="onSubmit">
							{{ $t("Submit") }}
						</b-button>
						<b-button size="sm" type="button" variant="outline-secondary" @click="hide">
							{{ $t("Cancel") }}
						</b-button>
					</div>
				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import { computed, getCurrentInstance, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import { required } from "@validations";
	import Ripple from "vue-ripple-directive";
	import vSelect from "vue-select";
	import store from "@/store";
	import { showToast } from "@/libs/utils/showToast";

	export default {
		components: {
			vSelect,
			ValidationProvider,
			ValidationObserver,
		},
		directives: {
			Ripple,
		},
		model: {
			prop: "isAsignedProjectSidebarActive",
			event: "update:is-asigned-project-sidebar-active",
		},
		props: {
			isAsignedProjectSidebarActive: {
				type: Boolean,
				required: true,
			},
			user_id: {
				type: Number,
			},
			hasProject: {
				type: Array,
			},
		},
		watch: {
			hasProject(val) {
				this.hasList = val;
				if (val) {
					this.projectList = JSON.parse(JSON.stringify(this.AllProject));
					this.hasProject.forEach((ele, index) => {
						// hasProject存放的是已有项目的id数组
						this.projectList.forEach((item, i) => {
							//  projectList存放的是所有的项目数据
							if (ele === item.project_id) {
								this.projectList.splice(i, 1);
							}
						});
					});
				}
			},
		},

		setup(props, { emit }) {
			const instance = getCurrentInstance().proxy;
			const event = reactive({
				blankUserData: {
					role_id: null,
					project_id: null,
				},
				required,
				roleOptions: [],
				projectOptions: [],
				projectList: [],
				hasList: [],
				AllProject: [],
				userid: computed(() => {
					return props.user_id;
				}),
			});

			watch(
				() => event.projectList,
				(val) => {
					if (val) {
						emit("isAssign", val);
					}
				}
			);

			const selectProject = (value) => {
				store.dispatch("users/getRole", { project_id: value }).then((response) => {
					console.log(response.data, "response");
					event.roleOptions = JSON.parse(JSON.stringify(response.data));
				});
			};
			const onSubmit = () => {
				store
					.dispatch("users/assignProject", {
						user_id: event.userid,
						...event.blankUserData,
					})
					.then(() => {
						showToast(null, "分配成功", "您已成功将项目分配到该用户下");
						emit("refetch-data");
						emit("update:is-asigned-project-sidebar-active", false);
					})
					.catch((error) => {
						showToast(error);
					});
			};
			const resetForm = () => {
				event.blankUserData.role_id = null;
				event.blankUserData.project_id = null;
				instance.$refs.errorText1.innerHTML = "";
				instance.$refs.errorText2.innerHTML = "";
			};

			onMounted(() => {
				store.dispatch("users/fetchProjects", { pagesize: 999999 }).then((response) => {
					event.AllProject = JSON.parse(JSON.stringify(response.data));
					event.projectList = JSON.parse(JSON.stringify(event.AllProject));
					// 将用户中含有的项目去除
					props.hasProject.forEach((ele, index) => {
						// hasProject存放的是已有项目的id数组
						event.projectList.forEach((item, i) => {
							//  projectList存放的是所有的项目数据
							if (ele === item.project_id) {
								event.projectList.splice(i, 1);
							}
						});
					});
				});
			});

			return {
				...toRefs(event),
				onSubmit,
				resetForm,
				selectProject,
			};
		},
	};
</script>

<style lang="scss">
	@import "src/@core/scss/vue/libs/vue-select.scss";

	#add-new-user-sidebar {
		.vs__dropdown-menu {
			max-height: 200px !important;
		}
	}
</style>
